import React from 'react';
import { navigate } from "gatsby";
import { Router } from "@reach/router";
import { RentBooth } from '../Views';
import SEO from '../components/seo';

function Rent(props) {
    if (props["*"] === "") navigate("/404")
    return (
        <>
            <SEO title="Rent Booth" />
            <Router>
                <RentBooth path="/rentbooth/:id"/>
            </Router>
        </>
    )
}

export default Rent;
